import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';


const ProtectedRoute = ({ element: Component }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('https://api.zenawiportal.co.ke/api/profile');
        if (response.data.valid) {
          setIsAuthenticated(true);
          setEmail(response.data.email);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error checking authentication', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or loading component
  }

  return isAuthenticated ? <Component email={email} /> : <Navigate to="/auth/login" />;
};

export default ProtectedRoute;

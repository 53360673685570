import {React,useState,useEffect }from 'react'
import { Document, Text,Image,Page,PDFViewer } from '@react-pdf/renderer';
import './sales.css'
import Axios from 'axios';
import { useSearchParams } from "react-router-dom";


export default function Salespdf() {
    // const location = useLocation();
    const[searchParams] = useSearchParams();
    const[clientId,setClientId] = useState('');
    const[clientPhone,setClientPhone]= useState('');

          useEffect(() =>{
        Axios.get(`https://api.zenawiportal.co.ke/api/thisClient?name=${searchParams.get("client")}`).then((res)=>{
            setClientId(res.data.idNumber);
            setClientPhone(res.data.phoneNumber);
         
        })
      },[])
   
   
   
  return (
    <div >
        <PDFViewer style={{width:'1200px', height:'800px'}}>
    <Document  >
    <Page>
    <Image src="/assets/zenawiLogo.png" style={{width:'150px', height:'110px',marginLeft:'35%'}} className='pdfLogo' />
    <Text style={{marginLeft:'40%', marginTop:'7%', fontSize:'13px'}}>SALE AGREEMENT</Text>
    <Text style={{marginLeft:'42%', marginTop:'5%', fontSize:'13px'}}>BETWEEN</Text>
    <Text style={{marginLeft:'38%', marginTop:'7%', fontSize:'13px'}}>ZENAWI HOMES LIMITED</Text>
    <Text style={{marginLeft:'42%', marginTop:'2%', fontSize:'13px'}}>(The "VENDOR")</Text>
    <Text style={{marginLeft:'20%', marginTop:'5%', fontSize:'13px'}}>____________________________________________________</Text>
    
    <Text style={{marginLeft:'49%', marginTop:'5%', fontSize:'13px'}}>And</Text>
    <Text style={{marginLeft:'45%', marginTop:'2%', fontSize:'13px'}}>{searchParams.get("client")}</Text>
    <Text style={{marginLeft:'42%', marginTop:'9%', fontSize:'13px'}}>(The "PURCHASER")</Text>


    <Text style={{marginLeft:'36%', marginTop:'9%', fontSize:'13px'}}>IN REGARD TO SALE AND TRANSFER OF:</Text>
    <Text style={{marginLeft:'20%', fontSize:'13px'}}>____________________________________________________</Text>
    <Text style={{marginLeft:'30%',marginTop:'2%', fontSize:'13px'}}>PROJECT {searchParams.get("projectName")}/PLOT NO.{searchParams.get("plotNumber")}</Text>

    <Text style={{marginLeft:'49%', marginTop:'15%', fontSize:'12px', textDecoration:'underline'}}>DRAWN BY:</Text>
    <Text style={{marginLeft:'42%', marginTop:'1%', fontSize:'12px'}}>Njeri Kabinu & Co. Advocates</Text>
    <Text style={{marginLeft:'47%', marginTop:'1%', fontSize:'12px'}}>Waira Kamau Building</Text>
    <Text style={{marginLeft:'50%', marginTop:'1%', fontSize:'12px'}}>Ground Floor</Text>
    <Text style={{marginLeft:'47%', marginTop:'1%', fontSize:'12px'}}>P.O Box 1209 - 00232</Text>
    <Text style={{marginLeft:'53%', marginTop:'1%', fontSize:'12px'}}>Ruiru</Text>

    <Text style={{marginLeft:'36%', marginTop:'12%', fontSize:'13px'}}>THIS AGREEMENT IS MADE on DATE</Text>
    <Text style={{marginLeft:'46%', marginTop:'5%', fontSize:'13px'}}>BETWEEN</Text>


    <Text style={{fontSize:'11px', marginLeft:'5%',marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}> ZENAWI HOMES LIMITED Registration No. PVT-3QU7RKMQ. OF Po Box Number 47705-
    00100 Nairobi, Kenya in the Republic aforesaid (the 'Vendor') which expression shall where the context so admits
    include its successors and assigns of the other part </Text>
    <Text style={{marginLeft:'50%', marginTop:'3%', fontSize:'13px'}}>AND</Text>
    <Text style={{fontSize:'11px', marginLeft:'5%',marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
    {searchParams.get("client")} of ID: {clientId} and Phone Number: {clientPhone}
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'5%', marginRight:'5%', lineHeight:'1.5px'}}>
        in the Republic aforesaid(the "Purchaser") which expression shall the context so admits include his personal
        representatives and assigns of the other part
    </Text>
    <Text style={{marginLeft:'5%', marginTop:'3%', fontSize:'13px',textDecoration:'underline'}}>WHEREAS:-</Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        A. The Vendor is registered as the absolute proprietor of a freehold interest in that parcel of Land
        known as Project {searchParams.get("projectName")}/Plot No:{searchParams.get("plotNumber")}(Hereinafter referredto as 'the property').
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        B. The Vendor agrees to sell and the Purchaser agrees to purchase the said property free from the
         encumbrances and upon the following terms and conditions
    </Text>
    <Text style={{marginLeft:'5%', marginTop:'3%', fontSize:'13px',textDecoration:'underline'}}>NOW THIS AGREEMENT WITNESSES as follows:</Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>a. Purchase Price</Text>

    <Text style={{fontSize:'11px', marginLeft:'5%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        The Vendor shall sell and the Purchaser shall purchase the Property as the agreed consideration of {searchParams.get("words")}(Kshs {searchParams.get("amount")})
        (the "Purchaser Price")
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        i.  Kenya shillings Kshs {searchParams.get("depost")} /= shall be paid to the vendors bank on or before specified date.
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        ii. The balance of Ksh {searchParams.get("balance")} shall be paid in installments of Amount in words(Amount in Digits)
        every month for {searchParams.get("months")} months

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        iii.    The Vendors Bank Details are:
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'12%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>Bank:       KCB BANK</Text>
    <Text style={{fontSize:'11px', marginLeft:'12%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>Account Number:       1292819626</Text>
    <Text style={{fontSize:'11px', marginLeft:'12%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>Branch:       Garden City</Text>
    <Text style={{fontSize:'11px', marginLeft:'12%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>Name:       ZENAWI HOMES LIMITED</Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        iv.    If the balance is payable in a period not exceeding three (3) months, the purchase price shall be adjusted and subtracted by Ksh. 10,000 /=
        which shall apply in each month from the fourth (4th) month to the last month of the installment payable in the event the client pays earlier
        than the agreed period as per clause 1.2.
    </Text>

    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'9%', marginRight:'5%', lineHeight:'1.5px'}}>
        v.   In the event the Purchaser fails to pay the balance of the purchase price at the completion date in clause 3, an interest rate of 10% of the
         balance of the Purchase Price shall apply each month till the third month from the completion date. If by the third(3rd) month, the Purchaser
          shall still not be able to complete the transaction, the company may at its own discretion transfer the amounts paid for the parcel to any 
          other parcel of an amount equivalent upon deduction of the said monthly interest and the percentage dictated in clause 5.4 of this Agreement.
    </Text>

    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>b. Sale Subject to Law Society Conditions of Sale</Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        The sale is subject to the Law Society Condtions of Sale (2015 Edition) and all other applicable laws in so far as the are not inconsistent with
        the conditions contained in this Agreementment.
    </Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>c. Completion Date</Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        The date of completion shall be within {searchParams.get("months")} months from the date of execution of this sale agreement.

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>d. Completion</Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        On or before completion date and in exchange with the balance of the purchase price the Vendor shall deliver to the purchaser the following completion documents:-
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        i.  Original certificate of title in respect of the property;
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        ii. Duly completed and Executed Transfer (in triplicate) in favor of the Purchaser;
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        iii.    Land Control Board Consent to Transfer;

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        iv. Three(3) colored passport size photographs of the Vendor.
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        v. Copies of the Vendor's PIN Certificate and ID
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        vi. Any other Ownership Document for the property that may be in possession of the Vendor
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'5%', marginRight:'5%', lineHeight:'1.5px'}}>
        The documents listed in clauses 5.1 to 5.6 (both inclusive) are hereinafter referred to as the ("Completion Documents").

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        A penalty of Kenya Shillings Five Thousand only(Ksh. 5000/=) per month shall accrue where the Purchaser upon notification by the vendor
        to collect the original Title Deed/Certificate of Lease fails to do so within thirty(30) Days of notice.

    </Text>

    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'5%', marginRight:'5%', lineHeight:'1.5px'}}>e. Termination and Rescission</Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        i.  Either party being ready, able and willing to complete shall be entitled to terminate this Agreement on the breach or default of the other 
        party in complying with or performing its obligations in accordance with this Agreement and the Law Society conditions applicable to the same.

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'12%', marginRight:'5%', lineHeight:'1.5px'}}>
        ii. If the other Completion Date the Purchaser is not ready, able or willing to pay the entire Purchase Price as required the Vendor shall issue 
        the Purchaser with a twenty one (21) days' notice in writing to comply with his obligations and such notice shall specify the default 
        (the "Completion Notice") and the Purchaser shall be obligated to remedy the default within the period of the Completion Notice (time being of 
        essence ) and if the Purchaser then fails to comply with the completion Notice in full, then, without prejudice to any other rights or remedies 
        available  to the Vendor, the Vendor shall in his sole discretion have the right to either:

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'12%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        1. Rescind this Agreement and retain twenty percent (20%) of the Purchase Price (being the agreed liquidated damages) in cash purchase where there 
        no installments. The Purchaser will thereafter  be required to enter into another sale agreement on different terms in the event he wishe to 
        proceed with the transaction; or

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'11%', marginTop:'2%', marginRight:'5%', lineHeight:'1.5px'}}>
        2. Where the Purchaser fails to pay where the installments are allowed within the stipulated time, the Vendor may rescind this Agreement and retain 
        twenty percent (20%) of the Purchase Price together with ten percent (10%) interest for each defaulted month as per clause 1 of this Agreement 
        (Both the 20% of the Purchase Price and 10% penalty for every month defaulted being the agreed liquidated damages).


    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        iii. In the event the Purchaser fails to pay where installments are allowed within the stipulated time, the Vendor may rescind this Agreement and 
        retain twenty (20%) of the Purchase Price together with ten percent (10%) interest for each defaulted month as per clause 1 of this Agreement.

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        iv. If on completion Date the Vendor fails to comply with his/her obligations under this Agreement, the Purchaser may give the Vendor a completion Notice 
        which shall specify the default and require the Vendor to make it good with twenty-one (21) days of such notice (time being of the essence) and if the 
        Vendor fails to remedy the default within the specified period, the Purchaser shall at his/her/their/its sole discretion be entitled to;
    </Text>
    
        <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
            1. Extend the time for the completion; or
            </Text>
        <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
            2. Rescind this Agreement and a refund by the Vendor shall be made of all the monies paid
        </Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'5%', marginRight:'5%', lineHeight:'1.5px'}}>
        f. Refund

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        Any refunds that the purchaser shall be entitled  to from the Vendor in this Agreement shall be by way of Cheque

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        g. Possession
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        The Purchaser shall take temporary possession of the property upon the second payment as per clause 1.21 (Limited to fencing) 
        and full possession upon last payment as per clause 1.22.
    </Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        h. Vendor's Warranty

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        i. There is no adverse report by any surveyor, government ministry, National Land Commission or tribunal with regard to the report

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'7%', marginRight:'5%', lineHeight:'1.5px'}}>
        ii. The Property is not a buffer zone, road reserve or public land and the ownership thereof is not subject to any challenge
        whatsoever from the Government of Kenya or any County or local authority or any third party whatsoever (including private persons);

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        iii. The Vendor has the necessary rights and authority to sell the Property on the terms and conditions set out in this Agreement.

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        iv. There is no law or decree or similar enactment binding on the Vendor and no provision or indenture, trust deed, contract or agreement binding 
        on the Vendor which conflicts with or prevents the Vendor from entering into or performing and observing the terms of this Agreement; 

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        v. The Vendors are not engaged in nor to the best of the Vendor's knowledge threatened by any litigation, arbitration, or administrative 
        proceedings relating to the Property;

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        vi. There is no adverse claim on the Property, dispute regarding ownership, boundary, easement, rights of way or any other such matters;

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        vii. The vendors have not given any rights away, easement or any overriding interest and has no intention of so doing;

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        viii. The Vendors have disclosed to the Purchaser all material information relating to the Property; and

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        ix. The Vendors have not received any notice from the Government or county or municipal authority or from owners of adjoining properties, 
        which remain to be complied with and will immediately disclose in writing to the Purchaser any event or circumstance which may arise or
        become known to the Vendor after the date of this Agreement and prior to completion which is inconsistent with any of the warranties or 
        which had it occurred on or before the date of this Agreement would have constituted a breach of the above warranties or which is material 
        and requires to be known by the Purchaser.

    </Text>

    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>i. Beacons</Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        The Vendors shall point out the property beacons to the Purchaser before the Completion Date and shall replace any missing beacon(s)
        as the Vendor's cost and expense.

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>j. Cost</Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        Where the Purchase Price is financed or the Purchaser engages his/her/their own Advovate:
        10.1. Each party shall be responsible for and shall pay the legal fees and disbursement of the respective advocates in connection with this
        Agreement and the sale and Purchase of the Property. 10.2 All stamp duty and registration fees payable on the transfer shall be borne by
        the Vendor

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'7%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        k. Waiver

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'9%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        No failure or delay by the Vendors or the Purchaser in exercising any claim,remedy,right,power or privilege under this Agreement shall operate
        as a waiver nor shall any single  or partial exercise of any claim, remedy,right, power or privilege preclude any furthur exercise 
        thereof or the exercise of any other claim, right or power.

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        13. Survival

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'1%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        Save with regard to matters which require to be fulfilled and are infact fulfilled prior to or at the Compeletion Date, this Agreement shall 
        continue to be in full force and effect.
    </Text>
    <Text style={{fontSize:'12x', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
    14. Remedies Cumulative

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        Any remedy or right conferred upon the Vendors or the Purchaser for the breach of this Agreement including the right of rescission shall be
         in addition to and without prejudice to all other rights and remedies available to them.

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        15. Amendment

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        No amendment to this Agreement shall be effective unless signed in the same manner as this Agreement.

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        16. Notice

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        Any notice to be given to either party to this Agreement shall be in writing and shall be deemed to be duly served upon hand delivery by 
        either party to the other, or upon delivered by registered post to the party's aforesaid postal address or to any other address which 
        either party may in writing inform the other. Any notice or communication served by registered post shall be deemed to have been 
        delivered within the seven (7) days following the day on which it is posted. 

    </Text>

    <Text style={{fontSize:'12px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        17. Custody of Original Documents
    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        The original title deed and all other original documents in respect of the property shall be kept in custody of the Vendor and shall be 
        released to the Purchaser on the completion date.

    </Text>
    <Text style={{fontSize:'12px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        18. Entire Agreement

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        This Agreement contains the whole agreement and understanding between the Parties relating to the transaction provided for this Agreement 
        and supercedes all previous agreements(if any) whether written or oral between the Parties in respect of such matters.

    </Text>

    <Text style={{fontSize:'12px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        19. Disputes and Artbitration

    </Text>
    <Text style={{fontSize:'11px', marginLeft:'7%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        Should any dispute arise between the Parties hereto with regard to the interpretation, rights, obligations and/or implementations of any one 
        or more of the provisionss of this Agreement, the Parties to such dispute shall in the first instance attempt to resolve such dispute 
        by amicable negotiation. Should such negotiations fail to achieve a resolution within Fifteen (15) days, either party may declare a dispute 
        bby written notification to the other, whereupon such dispute shall be referred to arbitration and such arbitraitor appointed shall be 
        guided by the provisions of the Kenyan Arbitration Act 1995(as amended from time to time).

    </Text>


    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'5%', marginRight:'5%', lineHeight:'1.5px', textDecoration:'underline'}}>
        IN WITNESS WHEREOFF <Text style={{fontSize:'11px'}}>the Vendors and the Purchaser hereto have executed this Agreement the day and year first hereinbefore written.</Text>


    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>
        SIGNED BY <Text style={{fontSize:'11px'}}>by the said vendor</Text>
        
    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        DIRECTOR ZENAWI HOMES LIMITED

    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>
        ROBERT MAINA MUICHUI ............................................

    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>

        ID NO: <Text style={{fontSize:'11px'}}>28625307</Text>
    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'3%', marginRight:'5%', lineHeight:'1.5px'}}>

        SIGNED: <Text style={{fontSize:'11px'}}>by the purchaser</Text>
    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>

        Name: <Text style={{fontSize:'11px'}}>{searchParams.get("client")}</Text>
    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>

        ID: <Text style={{fontSize:'11px'}}>{clientId}</Text>
    </Text>
    <Text style={{fontSize:'13px', marginLeft:'5%', marginTop:'1%', marginRight:'5%', lineHeight:'1.5px'}}>

        Phone Number: <Text style={{fontSize:'11px'}}>{clientPhone}</Text>
    </Text>

    </Page>
</Document>
</PDFViewer>
</div>
  )
}
